.App {
  text-align: center;
}

* { 
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

*:focus {
  outline: none;
}

body {
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.test {
  position: absolute;
  left: 16px;
  top: 16px;
}

.center-container {
  width: 100%;
  position: absolute;
  top: 66px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin: auto;
  display: flex;
}

.center-container.top {
  top: 100px;
}

.center-container.bottom {
  bottom: 66px;
}

/* Hide scrollbar for Chrome, Safari, and newer versions of Edge */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
/* Hide scrollbar for Internet Explorer and Edge */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media (min-width: 992px) {
  .showOnSmallDevices {
    display: none;
  }
}

@media (max-width: 992px) or (max-height: 500px) {
  .hideOnSmallDevices {
    display: none;
  }
  .center-container.top {
    top: 66px;
  }
}


