.button {
  border-radius: 200px;
  background: #ebebeb;
  display: flex;
  width: fit-content;
  height: 44px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  box-sizing: border-box;
  min-width: 44px;
}

.button.noRound {
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
}

.button > .cta {
  color: var(--Blackish, #272727);
  leading-trim: both;
  text-edge: cap;

  /* Portal Desktop/Baseline Medium */
  font-family: Helvetica Neue;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button.active {
  border-radius: 26px;
  background: #326d43;
}

@media (hover: hover) {
  .button:hover {
    background: #cccccc;
  }
}

.content {
  position: absolute;
  top: 60px;
  left: 0px;
  z-index: 5;
}

.right > div > .content {
  left: unset;
  right: 0px;
}

.sideContent {
  display: flex;
  /* position: absolute; */
  top: 0px;
  left: 60px;
  height: 60px;
  order: 0;
  flex: 1;
}

.button-container {
  position: relative;
}

@media (max-width: 992px) or (max-height: 500px) {
  .button:not(.loaderContent .button) {
    background: none !important;
  }

  .button > .cta {
    font-size: 14px;
  }

  .sideContent {
    order: 1;
    width: 100%;
    margin: 0px 10px;
    justify-content: space-between;
    flex: unset;
  }

  .sideContent .button-container {
    width: 150px;
  }

  .right {
    order: 0;
  }
}
