video{
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.vignette-overlay {
    display:none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 92%;
    pointer-events: none; /* Allows clicks to reach the video element below */
    /* background-image: radial-gradient(circle, transparent 0%, rgb(255, 255, 255) 100%); */
    background-image:  radial-gradient(
        ellipse 90% 50% at center, /* Changing the shape of the gradient to ellipse */
        rgba(255, 255, 255, 0) 0%,
        rgba(63, 52, 52, 0) 25%,
        rgba(255, 255, 255, 0.0) 00%,
        /* rgb(255, 255, 255) 90%, */
        rgb(255, 255, 255) 100%
      );;
  }