.stepper {
  display: inline-flex;
  padding: var(--spacing-spacing-none, 0px);
  align-items: center;
  gap: 24px;
  position: absolute;
  top: 16px;
  left: 0px;
  width: 100%;
  /* align-items: center; */
  margin: auto;
  justify-content: center;
}

.stepper > * {
  z-index: 2;
}

.step-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Helvetica Neue;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.stepButton {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}


@media (hover: hover) {
  .stepButton:hover > svg > * {
    stroke: #666666;
  }
}

.stepButton.inActive > svg > * {
  stroke: #cccccc;
}

@media (max-width: 992px) or (max-height: 500px) {
  .stepper {
    border-radius: 200px;
    background: rgba(0, 0, 0, 0.5);
    gap: 8px;
    width: fit-content;
    height: fit-content;
    position: absolute;
    right: 16px;
    bottom: 16px;
    top: unset;
    left: unset;
  }

  .step-indicator {
    color: var(--White, #fff);
    text-align: center;
  }
  .stepButton .turnWhite {
    stroke: white;
  }
}
