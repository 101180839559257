.card {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  display: flex;
  width: 364px;
  /* padding: 24px; */
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  max-height: calc(100vh - 156px);
  z-index: 10;
}

.overlayHidder {
  height: 50px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  );
  /* backdrop-filter: blur(6px); */
  border-radius: 0px 0px 12px 12px;
  left: 0px;
}

.title {
  color: var(--Blackish, #272727);
  leading-trim: both;
  text-edge: cap;

  /* Portal Desktop/Heading 3 */
  font-family: Literata;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0px;
}

.close-btn {
  width: 30px;
  height: 24px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 16px;
}
@media (hover: hover) {
  .close-btn:hover > svg > * {
    stroke: #666666;
  }
}
.card-body {
  overflow: scroll;
  margin-left: 24px;
  margin-right: 24px;
  padding-bottom: 50px;
  max-height: calc(100vh - 377px);
  backdrop-filter: url();
}

.card-header {
  display: flex;
  padding: var(--spacing-spacing-none, 0px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin: 24px;
}

/* Additional styles can be added based on your requirements */

.sideNote {
  width: fit-content;
  white-space: nowrap;
}
.sideNote > .group {
  color: #000;
  leading-trim: both;
  text-edge: cap;

  /* Portal Desktop/Smaller Medium */
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.7;
}
.sideNote > .title {
  color: #000;
  leading-trim: both;
  text-edge: cap;

  /* Portal Desktop/Big */
  font-family: Helvetica Neue;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.card-overlay {
  display: contents;
}

@media (max-width: 992px) or (max-height: 500px) {
  .card {
    display: flex;
    width: 350px;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    position: fixed;
    top: 0px;
    border-radius: 0px;
    max-width: 40vw !important;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
  }

  .card-scroller {
    height: 100%;
  }

  .card.left {
    left: 0px;
  }

  .card.right {
    right: 0px;
  }

  .sideNote {
    width: fit-content;
    white-space: nowrap;
  }

  .card-body {
    overflow: scroll;
    margin-left: 24px;
    margin-right: 24px;
    padding-bottom: 50px;
    backdrop-filter: url();
    max-height: unset;
  }

  .card-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.3);
    top: 0px;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-width: unset !important;
    position: fixed;
    left: 0px;
    /* left: min(348px, calc(40vw + 48px)); */
    z-index: 10;
  }
}

@media (max-width: 680px) {
  .menu-backdrop {
    height: 180px;
  }
  .card {
    max-width: 100vw !important;
  }
}
