.menu {
  width: 100%;
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  box-sizing: border-box;

  justify-content: space-between;
  /* align-items: center; */
  align-self: stretch;
  flex-flow: wrap;
  /* background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%); */
  z-index: 6;
}

.menu-backdrop {
  position: absolute;
  width: 100%;
  top: 0px;
  height: 120px;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

/* .menu > * {
  z-index: 1;
} */

.menu > .right {
  display: flex;
  padding: var(--spacing-spacing-none, 0px);
  align-items: flex-start;
  gap: 4px;
  max-width: 50vw;
}

.bottom.right {
  position: absolute;
  bottom: 0;
  right: 0px;
  margin: 16px;
}

@media (max-width: 992px) or (max-height: 500px) {
  .menu-backdrop {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%);
  }
}

@media (max-width: 680px) {
  .menu > .right {
    flex-wrap: wrap;
    justify-content: right;
    order: 0;
  }
  .menu-backdrop {
    height: 180px;
  
  }
}

@media (max-width: 410px) {
  .menu > .right {
    flex-wrap: wrap;
    justify-content: right;
    max-width: unset;
    order: 0;
  }
}
