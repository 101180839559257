.progress-bar {
  display: flex;
  height: 4px;
  padding: var(--spacing-spacing-none, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 80px;
  background: rgba(211, 211, 211, 0.4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  width: 100%;
}

.filled {
  border-radius: 20px;
  background: none;
  width: 0;
  flex: 1 0 0;
  overflow: hidden;
}

.filled-total {
  border-radius: 20px;
  background: linear-gradient(270deg, #b73f08 0.06%, #fbc4ac 99.29%);
  width: 100vw;
  width: 30;
  height: 100%;
}
