.button .inner-rect {
  transition: x 0.3s ease;
}

.button.selected .outer-rect {
  fill: #1b7b06;
}

.button.selected .inner-rect {
  x: 13px;
  fill: white;
}

@media (hover: hover) {
  .button:hover .outer-rect {
    fill: #343434;
  }

  .button.selected:hover .outer-rect {
    fill: #375330;
  }
}
