.list-view {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Helvetica Neue;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.title-style {
  margin-bottom: 8px;
  flex-wrap: wrap;
  display: flex;
  gap: 6px;
  height: 20px;
}

.title-style > div > .gr {
  cursor: pointer;
}

.title-style > div > .en {
  cursor: pointer;
}

.group-style {
  color: #868686;
  leading-trim: both;
  text-edge: cap;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
  margin-top: 25px;
  flex-wrap: wrap;
  display: flex;
  gap: 6px;
}

.bullet {
  width: 11px;
  height: 11px;
  z-index: 2;
}

.borderLeft {
  height: 75px;
  position: sticky;
  border-color: #326d43;
  border-width: 0px 0px 0px 2px;
  border-style: solid;
  opacity: 1;
  transform: translateX(-12.5px) translateY(-60px);
  z-index: 1;
}

.borderLeft + div {
  transform: translateX(-8px);
}

.active > .borderLeft {
  border-color: var(--Dark-orange, #b73f08);
  z-index: 100;
}

.past > .borderLeft {
  border-color: var(--Dark-orange, #b73f08);
  z-index: 100;
}

.title-style.active > div {
  color: var(--Dark-orange, #b73f08);
  leading-trim: both;
  text-edge: cap;

  /* Portal Desktop/Small Medium */
  font-family: Helvetica Neue;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.title-style.past > div {
  color: var(--color-grey-600, #666);
  leading-trim: both;
  text-edge: cap;
  
  /* Portal Desktop/Small Regular */
  font-family: Helvetica Neue;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.title-style > div > svg {
  fill: var(--Dark-orange, #326d43);
}

.title-style.active > div > svg {
  fill: var(--Dark-orange, #b73f08);
}

.title-style.past > div > svg {
  fill: var(--Dark-orange, #b73f08);
}


.list-view > div:nth-child(1) > .group-style {
  margin-top: 8px;
}